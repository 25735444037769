<template>
  <calendesk-tabs v-show="getStatsLoaded" :items="getItems" :route="route" />
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import path from "@/router/path";
import CalendeskTabs from "@/components/CTabs/CalendeskTabs.vue";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";

export default {
  name: "ToolsWrapper",
  components: { CalendeskTabs },
  mixins: [sharedActions, roleActions, sharedTabsActions],
  data() {
    return {
      route: path.billing.main,
    };
  },
  computed: {
    getItems() {
      const items = [];

      if (this.loggedUserCanSeePayments) {
        items.push({
          id: this.availableTabs.billing.data,
          title: this.$trans("invoice_data"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.billing.data },
          },
          content: () =>
            import("@/views/dashboard/pages/Billing/PaymentsSettings"),
        });

        items.push({
          id: this.availableTabs.billing.invoices,
          title: this.$trans("my_invoices"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.billing.invoices },
          },
          content: () =>
            import("@/views/dashboard/pages/Billing/PaymentsInvoices"),
        });

        items.push({
          id: this.availableTabs.billing.subscription,
          title: this.$trans("subscription"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.billing.subscription },
          },
          content: () =>
            import("@/views/dashboard/pages/Billing/CurrentPackages"),
        });
      }

      return items;
    },
  },
};
</script>
